import { createInput } from '@gluestack-ui/input';
import { Root, Icon, Slot, StyledInput } from './styled-components';
const AccessibleInput = createInput({
    Root,
    Icon,
    Slot,
    Input: StyledInput,
});
export const Input = AccessibleInput;
export const InputIcon = AccessibleInput.Icon;
export const InputSlot = AccessibleInput.Slot;
export const InputField = AccessibleInput.Input;
/**
 * @deprecated Use InputField instead.
 */
export const InputInput = AccessibleInput.Input;
